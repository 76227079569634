<template>
  <v-layout
    class="phone-text-field"
    :class="{
      'mt-3': !hideTopMargin,
      className: className,
    }"
  >
    <v-flex class="max-width-80px">
      <v-select
        outlined
        v-model="countryCode"
        :items="countryList"
        hide-details
        :disabled="disabled"
        :loading="loading"
        item-text="name"
        item-value="iso2"
        item-color="blue darken-4"
        return-object
        v-on:change="updateActiveCountry(phone)"
      >
        <template v-slot:selection>
          <div :class="activeCountry.iso2.toLowerCase()" class="vti__flag mr-2" />
        </template>
        <template v-slot:item="data">
          <div class="d-flex justify-center align-center" @click="countryClicked(data)">
            <div :class="data.item.iso2.toLowerCase()" class="vti__flag mr-2" />
            <span>{{ data.item.name }} {{ `+${data.item.dialCode}` }}</span>
          </div>
        </template>
      </v-select>
    </v-flex>
    <v-flex>
      <v-text-field
        :id="id"
        v-model="phone"
        outlined
        :prefix="getPrefix"
        :rules="rules"
        hide-details
        :disabled="disabled"
        :class="{
          required: required && !phone ? true : false,
        }"
        :loading="loading"
        type="number"
        hide-spin-buttons
        v-on:keypress="disableCharacter($event, phone)"
        :placeholder="placeholder"
        v-on:paste="$emit('paste', $event)"
        @keydown="emitValue(), validatePhoneNumber()"
        v-on:change="
          emitValue();
          validatePhoneNumber();
        "
      >
      </v-text-field>
      <span
        v-if="fieldNameError"
        v-html="fieldNameError"
        class="red--text text--darken-1 font-small"
      ></span>
    </v-flex>
  </v-layout>
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
<script>
//import SelectInput from "@/view/components/SelectInput.vue";
//import { trimStart } from "lodash";
import { mapGetters } from "vuex";
import allCountry from "@/assets/js/components/country-list.js";
import MainMixin from "@/core/mixins/main.mixin.js";
// import allCountries from "@/core/plugins/country-list";
import { find, split, replace } from "lodash";
import { ValidateInputField } from "@/core/lib/common.lib";

export default {
  name: "text-phone-input",
  mixins: [MainMixin],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    rules: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
    value: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: "phone",
    },
    className: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "Phone Number",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hideTopMargin: {
      type: Boolean,
      default: false,
    },
    parentId: {
      type: [String, Number],
      default: null,
    },
    currentId: {
      type: [String, Number],
      default: null,
    },
    validationField: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      phone: null,
      fieldNameError: null,
      inputvalue: null,
      phone_number_limit: 8,
      countryCode: {
        areaCodes: null,
        dialCode: "65",
        iso2: "SG",
        name: "Singapore",
        priority: 0,
      },
      activeCountry: {
        areaCodes: null,
        dialCode: "65",
        iso2: "SG",
        name: "Singapore",
        priority: 0,
      },
      phoneMask: "########",
      countryDialCode: "+65",
      countryList: [],
    };
  },
  components: {
    //SelectInput,
  },
  watch: {
    value(param1, param2) {
      if (param1 != param2) {
        this.setValues();
      }
    },
  },
  methods: {
    setValues() {
      const value = replace(this.value, "+", "");
      const phoneCode = split(value, "-");
      this.defaultDial = this.countryList[0];
      this.countryList.forEach((ele) => {
        if (ele.phone_code == phoneCode[0]) {
          this.phone_number_limit = ele.phone_number_limit;
        }
      });
      if (phoneCode.length != 2) {
        const countryCode = find(this.countryList, {
          dialCode: "62",
        });
        if (countryCode) {
          if (this.phone) {
            this.countryCode = countryCode;
            this.activeCountry = countryCode;
          }
        }

        this.phone = null;
        return false;
      }
      if (phoneCode && phoneCode[0]) {
        const countryCode = find(this.countryList, {
          dialCode: phoneCode[0],
        });
        if (countryCode) {
          this.countryCode = countryCode;
          this.activeCountry = countryCode;
        }
      }
      if (phoneCode[0]) {
        if (phoneCode[0] == "62") {
          this.countryDialCode = `+${phoneCode[0]}`;
          //this.phoneMask = `########`;
        } else {
          this.countryDialCode = `+${phoneCode[0]}`;
          //this.phoneMask = `##########`;
        }
      }
      // this.$nextTick(() => {
      this.phone = phoneCode[1] ? phoneCode[1] : null;
      // });
    },
    updateActiveCountry(phoneNumber) {
      console.log(phoneNumber, "phoneNumber");
      const { dialCode } = this.countryCode;
      if (dialCode) {
        if (dialCode == "60") {
          this.countryDialCode = `+${dialCode}`;
          this.phoneMask = `########`;
        } else {
          this.countryDialCode = `+${dialCode}`;
          this.phoneMask = `##########`;
        }
      }
      //this.phone = null;
      this.$nextTick(() => {
        this.activeCountry = this.countryCode;

        if (phoneNumber) {
          this.inputvalue = `${this.countryDialCode}-${phoneNumber}`;
          this.$emit("input", this.inputvalue);
        } else {
          this.$emit("input", null);
        }
      });
      this.validatePhoneNumber();
    },
    emitValue() {
      this.inputvalue = null;
      if (this.phone) {
        console.log(this.countryDialCode, "this.countryDialCode");
        this.inputvalue = `${this.countryDialCode}-${this.phone}`;
      } else {
        this.inputvalue = null;
      }
      this.$emit("input", this.inputvalue);
      this.$emit("change", this.inputvalue);
    },
    disableCharacter($event, value) {
      if (
        $event.keyCode === 45 ||
        $event.keyCode === 69 ||
        $event.keyCode === 101 ||
        $event.keyCode === 46
      ) {
        $event.preventDefault();
      }
      const { dialCode } = this.countryCode;
      const val = value ? value.toString() : "";
      if (dialCode == "62") {
        if (val.length == this.phone_number_limit) {
          $event.preventDefault();
        }
      } else {
        if (val.length == this.phone_number_limit) {
          $event.preventDefault();
        }
      }
    },
    validatePhoneNumber() {
      const _this = this;
      _this.fieldNameError = null;
      /* For Remove validation start */
      const validate = true;
      if (validate) {
        return false;
      }
      /* For Remove validation end*/
      if (this.validationField && !this.validationField.url_type) {
        return false;
      }
      if (!_this.inputvalue) {
        return false;
      }
      return new Promise((resolve, reject) => {
        _this.pageLoading = true;
        ValidateInputField(`validate/${this.validationField.url_type}/any-field`, {
          filter_type:
            _this.validationField && _this.validationField.filter_type
              ? _this.validationField.filter_type
              : null,
          field:
            _this.validationField && _this.validationField.field
              ? _this.validationField.field
              : null,
          value: _this.inputvalue,
          parentId: this.parentId,
          id: this.currentId,
        })
          .then((output) => {
            if (output.exists) {
              _this.fieldNameError = `<b class='fw-600'>${_this.inputvalue}</b> already exists`;
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            _this.pageLoading = false;
          });
      });
    },
    countryClicked(data) {
      console.log(data, "countryClicked");
      const country = data.item?.country_name?.split("(")[0];
      console.log(data.item, "country");

      this.$emit("countryChange", country);
      if (data.item.iso2 == "MY") {
        this.phone_number_limit = 12;
        this.phone = null;
      } else {
        this.phone_number_limit = 8;
        this.phone = null;
      }
    },
  },
  computed: {
    ...mapGetters(["localDB"]),
    // rules() {
    // const { dialCode } = this.countryCode;
    /* const maxLimit = dialCode == "62" ? 8 : 10;
			const minLimit = dialCode == "62" ? 8 : 10; */
    // const numberLimit = dialCode == "62" ? 18 : 10;
    // console.log(numberLimit);
    // console.log(this.phone);
    /* const rule = [
				this.vrules.minLength(this.phone, "phone number", minLimit),
				this.vrules.maxLength(this.phone, "phone number", maxLimit),
			]; */
    // const rule = [this.vrules.phoneNumber(this.phone, "phone number", this.phone_number_limit)];
    // if (this.validationField && this.validationField.url_type) {
    // 	rule.push(this.fieldNameError ? false : true);
    // }
    // if (this.required) {
    // 	rule.push(this.vrules.required(this.phone, "phone number"));
    // }
    // return rule;
    // },
    getPrefix() {
      return `${this.countryDialCode}-`;
    },
    getPhoneMask() {
      return this.phoneMask;
    },
  },
  mounted() {
    this.countryList = allCountry;
    // this.countryList = this.localDB("country", 0);
    this.setValues();
  },
};
</script>
