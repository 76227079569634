<template>
  <v-text-field
    :id="id"
    :label="label"
    :loading="loading"
    :disabled="disabled"
    :hide-details="hideDetails"
    outlined
    v-mask="'######'"
    class="mt-2 pt-0"
    v-model="textinput"
    :rules="rules"
    :placeholder="placeholder"
  ></v-text-field>
</template>
<script>
export default {
  name: "text-input",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    rules: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
    value: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: "Postal Code",
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      textinput: null,
    };
  },
  watch: {
    value() {
      this.textinput = this.value;
    },
    textinput() {
      this.$emit("input", this.textinput);
    },
  },
  mounted() {
    this.textinput = this.value;
  },
};
</script>
